




























































































































































































































import { Component, ProvideReactive, Watch } from "vue-property-decorator";
import Actions from "@/components/Actions.vue";
import CreateButton from "@/components/Button/CreateButton.vue";
import Table from "@/components/Table.vue";
import EditButton from "@/components/Button/EditButton.vue";
import RemoveButton from "@/components/Button/RemoveButton.vue";
import MailButton from "@/components/Button/MailButton.vue";
import { namespace } from "vuex-class";
import UploadButton from "@/components/Button/UploadButton.vue";
import VerticalDivider from "@/components/VerticalDivider.vue";
import Avatar from "@/components/Avatars/Avatar.vue";
import FileCsvButton from "@/components/Button/FileCsvButton.vue";
import { mixins } from "vue-class-component";
import ViewSwitchMixin from "@/mixins/ViewSwitchMixin";
import FscCardHeader from "@/components/Card/FscCardHeader.vue";
import FscPageHeader from "@/components/FscPageHeader.vue";
import AuthImage from "@/directives/AuthImage";
import ShoppingCartButton from "@/components/Button/ShoppingCartButton.vue";
import SaldoButton from "@/components/Button/SaldoButton.vue";
import AbortButton from "@/components/Button/AbortButton.vue";
import SaveButton from "@/components/Button/SaveButton.vue";
import QrcodeButton from "@/components/Button/QrcodeButton.vue";
import { downloadFile, getFile } from "@/utils/File";
import StudentFilter from "@/views/Student/StudentFilter.vue";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import includes from "lodash/includes";
import StudentUploadDocument from "@/views/Student/StudentUploadDocument.vue";
import MandatePreviewDocument from "@/views/Student/MandatePreviewDocument.vue";
import StudentDetails from "@/views/Student/StudentDetails.vue";
import StudentInfoMixin from "@/mixins/StudentInfoMixin";
import StudentMixin from "@/mixins/StudentMixin";
import CreateNewEmailForm from "@/components/CreateNewEmailForm.vue";
import CustomSlider from "@/components/Slider/CustomSlider.vue";
import ActionButton from "@/components/Button/ActionButton.vue";
import { AxiosResponse } from "axios";
import { $axios } from "@/utils/axios";
import SaldoMixin from "@/mixins/SaldoMixin";
import FscModal from "@/components/Modal/FscModal.vue";
import { EmailTypeEnum } from "@/enums/EmailTypeEnum";
import { IStudentFilter } from "@/interfaces/IStudentFilter";
import clone from "lodash/clone";
import { formatStudentName } from "@/utils/NameUtil";
import _ from "lodash";

const StudentModule = namespace("student");
const MainTabsModule = namespace("main-tabs");
const MailboxModule = namespace("mailbox");
const MessagingModule = namespace("messaging");
const MandateModule = namespace("mandate");
const CostBearerModule = namespace("cost-bearer");
const StudentsFilterModule = namespace("students-filter");

@Component({
  methods: { formatStudentName },
  components: {
    ActionButton,
    CreateNewEmailForm,
    StudentDetails,
    MandatePreviewDocument,
    StudentUploadDocument,
    FscSimpleCard,
    QrcodeButton,
    SaveButton,
    AbortButton,
    ShoppingCartButton,
    FscPageHeader,
    FscCardHeader,
    FileCsvButton,
    Avatar,
    VerticalDivider,
    UploadButton,
    Actions,
    Table,
    CreateButton,
    EditButton,
    RemoveButton,
    SaldoButton,
    StudentFilter,
    MailButton,
    CustomSlider,
    FscModal,
  },
  directives: {
    AuthImage: new AuthImage(),
  },
})
export default class Students extends mixins(ViewSwitchMixin, StudentInfoMixin, StudentMixin, SaldoMixin) {
  public name = "Students";

  public fields = [
    {
      key: "name",
      label: this.$t("general.customer"),
      sortable: true,
    },
    {
      key: "costBearer",
      label: this.$t("general.cost_bearer"),
      sortable: false,
    },
    {
      key: "instructorsInitials",
      label: this.$t("general.driving_instructor"),
      sortable: false,
    },
    {
      key: "branchAbbreviation",
      label: this.$t("sidebar.branch"),
      sortable: true,
    },
    {
      key: "balance",
      label: this.$t("students.balance"),
      sortable: false,
      thClass: "d-flex justify-content-end",
    },
  ];

  public printDownloadDates: Array<string> = [];
  public selectedPrintDownloadDates: Array<string> = [];
  public datesLoading = false;

  public get notSelectedCertificateDate() {
    if (this.printOption !== "bkf-certificate") return false;
    return this.printOption === "bkf-certificate" && this.selectedPrintDownloadDates.length === 0;
  }

  @MainTabsModule.Action("setCustomLabel")
  private setCustomLabelAction: any;

  @StudentModule.Getter("getDataList")
  public items: any;

  @StudentModule.Getter("getTotal")
  public totalRows: any;

  @StudentModule.Action("filter")
  public studentFilter: any;

  @StudentModule.Getter("getIsLoading")
  public studentsLoading: any;

  @StudentModule.Action("setSelectedRowName")
  public setSelectedRowName!: (name: string) => void;
  @StudentsFilterModule.Getter("getCurrentFilter")
  public getCurrentFilter!: IStudentFilter | null;

  @MailboxModule.Action("fetchEmailsFilter")
  private fetchEmailsFilterAction: any;

  @MailboxModule.Getter("getIsLoading")
  private emailsFilterLoading: any;

  @MessagingModule.Action("filter")
  public filterStudents: any;

  @MessagingModule.Getter("getDataList")
  public allStudents: any;

  @MessagingModule.Getter("getIsLoading")
  public allStudentsLoading: any;

  @MandateModule.Action("findOne")
  public findIsDrivingSchoolEnabled: any;

  @MandateModule.Getter("getDataItem")
  public isDrivingSchoolEnabled: any;

  @CostBearerModule.Action("findOne")
  public findCostBearerByStudentId: any;

  @CostBearerModule.Getter("getDataItem")
  public costBearer: any;

  @StudentsFilterModule.Mutation("SET_CURRENT_FILTER")
  public setCurrentFilter!: any;

  public selectedRow: any = null;

  public balanceActive = 0;

  public balanceBottom = 0;

  public learnStatusActive = 0;

  public visible = false;
  public visibleEmailForm = false;
  public hideSearch = false;

  filteringIcon = false;

  protected query: any = { searchTerm: "" };
  protected visibleDocumentUpload = false;
  protected modalForEmailId = "send-user-email";

  protected printModalId = "print-modal";
  protected printOption = "";
  protected printOptions = [
    { text: "B96", value: "b96-certificate" },
    { text: "B196", value: "b196-certificate" },
    { text: "B197", value: "b197-certificate" },
    { text: "ASF", value: "asf-certificate" },
    { text: "MOFA", value: "mofa-certificate" },
    { text: "FES", value: "fes-certificate" },
    { text: "Grundqualifikation BKF", value: "bkf-basic-certificate" },
    { text: "Weiterbildung BKF", value: "bkf-certificate" },
    {
      text: this.$tc("general.training_certificate", 1),
      value: "training-certificate",
    },
  ];

  public selectedEmailType = null;
  public emailTypeOptions = [
    { text: this.$t("user.single_customer"), value: EmailTypeEnum.SELECTED_STUDENT },
    { text: this.$t("user.multiple_customers"), value: EmailTypeEnum.ALL_STUDENT },
    { text: this.$t("user.empty_email"), value: EmailTypeEnum.EMPTY },
  ];

  public generateCsvModalId = "generate-csv-modal-id";

  protected get filteredStudentEmails(): string {
    if (this.activeStudentsFilter || this.searchTerm) {
      return (
        this.items.map((student: any) => ({
          name: formatStudentName(student.firstName, student.lastName),
          email: student.email,
        })) || ""
      );
    }
    if (this.selectedRow) {
      return this.selectedRow.email || "";
    }
    return "";
  }

  public get mailOption() {
    return {
      cc: this.filteredStudentEmails,
      to: this.filteredStudentEmails,
      body: "",
      hasOptions: true,
    };
  }

  public get searchTerm(): string {
    return this.query?.searchTerm || "";
  }

  public active: any;
  private selectedRowIndex: any;
  private printLetterhead = false;

  public activeStudentsFilter: any = null;

  private initalSearchTerm = null;

  private registeredEvents: { eventName: string; handler: (...args: any[]) => void }[] = [];

  private registerEvent(eventName: string, handler: (...args: any[]) => void): void {
    this.$root.$on(eventName, handler);
    this.registeredEvents.push({ eventName, handler });
  }

  private unregisterAllEvents(): void {
    this.registeredEvents.forEach(({ eventName, handler }) => {
      this.$root.$off(eventName, handler);
    });
    this.registeredEvents = []; // Clear the list after unregistration
  }

  public created() {
    this.initalSearchTerm = this.$store.getters["student/searchLocalFilter/getFilter"];
  }

  public async mounted(): Promise<void> {

    this.registerEvent("keep-search-field", this.onKeepSearch);
    this.registerEvent("refresh-students-table", this.refreshStudentsTable);
    this.registerEvent("form-email-close", this.onEmailFormClose);

    this.findIsDrivingSchoolEnabled({
      resource: "live-pay/driving_school_enabled",
      id: "current"
    });

    this.$watch(
      () => this.$store.getters["student/searchLocalFilter/getFilter"],
      (value) => {
        (this.$refs.studentsTable as Table).onSearch(value || "");
      }
    );

    this.activeStudentsFilter = clone(this.getFilter);

    if (this.getCurrentFilter?.savedAt) {
      this.filteringIcon = true;
    }
  }

  beforeDestroy() {
    this.unregisterAllEvents();
  }

  public refreshStudentsTable() {
    this.requestQuery(this.query);
  }

  public onKeepSearch() {
    this.query = { ...this.query, searchTerm: this.$store.getters["student/localFilter/getFilter"]?.searchTerm };
    // this.requestQuery({ ...this.query, limit: 100 });
    const studentId = this.$route?.params?.id ? Number(this.$route?.params?.id) : 0;
    const selectedItem = studentId !== 0 ? this.items.filter((student: any) => student.id === studentId)[0] : this.items[0];
    if (selectedItem && (this.$refs.studentsTable as Table)) {
      (this.$refs.studentsTable as Table).onRowClicked(selectedItem, 0, null);
    }
  }

  public changeActiveLearnStatusTab(index: any): void {
    this.learnStatusActive = index;
  }

  public async requestQuery(query: Record<any, any>): Promise<any> {
    this.$store.commit("student/searchLocalFilter/SET_LOCAL_FILTER", query?.searchTerm || "");

    if (this.activeStudentsFilter) {
      this.activeStudentsFilter.limit = query.limit;
      this.activeStudentsFilter.searchTerm = query.searchTerm;
      this.activeStudentsFilter.offset = query.offset;
      this.studentFilter({
        resource: "students",
        filter: this.activeStudentsFilter,
      });
    } else {
      this.query = { ...this.query, ...query, searchTerm: query.searchTerm };
      await this.studentFilter({
        resource: "students",
        filter: { calculateSaldo: true, showArchived: false, ...this.query },
      });
    }
  }

  public onRowClicked(ctx: any, indexInTable: any) {
    this.selectedRowIndex = indexInTable;
    this.learnStatusActive = 0;
    this.visibleDocumentUpload = false;
    this.visibleEmailForm = false;
    this.openView();
    if (this.balanceActive === 1) {
      this.balanceActive = 0;
    }
    this.selectedRow = ctx;
  }

  public onEdit(): void {
    if (!this.selectedRow) return;
    this.setCustomLabelAction(formatStudentName(this.selectedRow.firstName, this.selectedRow.lastName));

    this.$router.push({
      name: "StudentEdit",
      params: { id: this.selectedRow.id },
    });
  }

  public balanceTabView(): any {
    return (this.balanceActive = 1), (this.balanceBottom = 3);
  }

  public resetBottomTab(tab: any): void {
    this.balanceBottom = tab;
  }

  public async resetStudents(): Promise<void> {
    this.balanceActive = 0;
    this.visibleEmailForm = false;
    this.selectedEmailType = null;
    if (this.activeStudentsFilter === null) {
      await this.studentFilter({
        resource: "students",
        filter: { calculateSaldo: true, showArchived: false, ...this.query },
      });
      return;
    }
    await this.studentFilter({
      resource: "students",
      filter: this.activeStudentsFilter,
    });
  }

  protected onClickEducationEvent(): void {
    if (!this.selectedRow) return;
    const fullName = this.getFullName(this.selectedRow);
    this.setCustomLabelAction(fullName);
    this.setSelectedRowName(fullName);
    this.$router.push({
      name: "EducationEventList",
      params: { id: this.selectedRow.id },
    });
  }

  protected getFullName(row: any): string {
    return formatStudentName(row.firstName, row.lastName);
  }

  @Watch("printOption", { immediate: true, deep: true })
  public async onLoadPrintDates() {
    if (this.printOption !== "bkf-certificate") return;
    this.datesLoading = true;
    const url = "bkf-certificate/get-date-options/" + this.selectedRow.id;
    await $axios
      .get(url)
      .then(({ data }: AxiosResponse) => {
        this.printDownloadDates = data;
        this.datesLoading = false;
      })
      .catch((e) => {
        console.error(e);
        this.datesLoading = false;
      });
  }

  protected onPrint(): void {
    if (!this.selectedRow) {
      this.$toasted.info("Please select a student!");
      return;
    }
    this.printOptionsReset();
    this.$bvModal.show(this.printModalId);
  }

  protected printOptionsReset() {
    this.printLetterhead = false;
    this.selectedPrintDownloadDates = [];
    this.printDownloadDates = [];
    this.printOption = "";
  }

  protected printOk(ok: any): void {
    if (!this.printOption) return;
    ok();
    if (this.printOption === "bkf-certificate") {
      this.getPrintWBCOption({
        url: this.printOption,
        studentId: this.selectedRow.id,
        printLetterhead: this.printLetterhead,
        dates: this.selectedPrintDownloadDates,
      });
      return;
    }
    this.getPrintOption(this.printOption, this.selectedRow.id, this.printLetterhead);
    this.printOption = "";
  }

  protected async getPrintOption(url: string, studentId: number, printLetterhead: boolean): Promise<void> {
    await getFile({
      method: "POST",
      url: `${url}?studentId=${studentId}&printLetterhead=${printLetterhead}`,
    });
  }

  protected async getPrintWBCOption(options: any): Promise<void> {
    const { url, studentId, printLetterhead, dates } = options;
    await getFile({
      method: "POST",
      url,
      data: {
        studentId,
        printLetterhead,
        dates,
      },
    });
  }

  protected onQRCode(): void {
    //this.$router.push({ name: "StudentUploadQrcodeDocument" });
    this.closeView();
    this.visibleDocumentUpload = true;
  }

  public onFilterModalPopup(): any {
    this.$bvModal.show("studentFilterPopover");
  }

  public onMouseOver(): any {
    this.visible = true;
  }

  public get getFilter() {
    const filter: IStudentFilter | any | null = _.cloneDeep(this.getCurrentFilter);

    if (!filter || Object.keys(filter).length === 0) return null;

    const mappedFilter = {
      calculateSaldo: true,
      fillingDate: filter.fillingDate || null,
      fillingDateFrom: filter.fillingDateFrom || null,
      fillingDateTo: filter.fillingDateTo || null,
      inactivePeriodFrom: filter.inactivePeriodFrom || null,
      inactivePeriodTo: filter.inactivePeriodTo || null,
      fillingDateYearMonth: filter.fillingDateYearMonth || null,
      instructorIds: this.pluck(filter.instructors),
      branchIds: this.pluck(filter.branches),
      costBearerIds: this.pluck(filter.costBearers),
      licenseClassNames: this.pluck(filter.licenseClasses, "name"),
      theoryCourseIds: this.pluck(filter.theoryCourses),
      priceListIds: this.pluck(filter.priceLists),
      grantingTypeId: filter.grantingType?.id || null,
      paymentWorkflowId: filter.paymentWorkflow?.id || null,
      livePayMandateStatusId: filter.livePayMandateStatus?.id || null,
      theoryExamPassed: filter?.theoryExamPassed === null ? null : filter?.theoryExamPassed,
      practicalExamPassed: filter?.practicalExamPassed === null ? null : filter?.practicalExamPassed,
      bf17: filter.bf17 || null,
      driveBuzz: filter.driveBuzz ? filter.driveBuzz.id : null,
      theoryLessons: filter.theoryLessons ? filter.theoryLessons.id : null,
      appInfo: filter.appInfo ? filter.appInfo.id : null,
      showArchived: filter.showArchived || false,
      includeArchivedEducations: filter.includeArchivedEducations || false,
      archivable: !filter.archivable ? null : filter.archivable?.archivable,
      lastNameStartingLetter: filter.lastNameStartingLetter || null,
      hasSignedContract: !filter.hasSignedContract ? null : filter.hasSignedContract.value,
      previewBalance: filter.previewBalance || null,
      balanceMoreThan: filter.balanceMoreThan || null,
      specialDrivingLessons: this.pluck(filter.specialDrivingLesson),
      testOrganisationId: filter.testOrganisation?.id || null,
    };
    return mappedFilter;
  }

  private pluck(items: Array<any> | null | undefined | any, field = "id") {
    if (items == null || items.length === 0) return null;
    if (Array.isArray(items)) {
      return items.map((item: any) => item[field]);
    }
    if (typeof items === "object") return items[field];
  }

  public async onStudentsFilter(data: { filter: any }): Promise<void> {
    this.setCurrentFilter(data.filter);

    const currentSearchTerm: string =
      this.$store.getters["student/searchLocalFilter/getFilter"] ||
      this.$store.getters["student/localFilter/getFilter"]?.searchTerm ||
      this.query.searchTerm ||
      "";

    this.query = { ...this.query, limit: this.activeStudentsFilter?.limit || 25, offset: 0 };
    this.activeStudentsFilter = {
      ...this.getFilter,
      limit: this.activeStudentsFilter?.limit || 25,
      offset: 0,
    };
    this.selectedRow = null;
    this.selectedEmailType = null;
    this.visibleEmailForm = false;
    await this.studentFilter({
      resource: "students",
      filter: {
        ...this.activeStudentsFilter,
        searchTerm: currentSearchTerm,
      },
    });
    this.filteringIcon = true;
    this.$bvModal.hide("studentFilterPopover");
    (this.$refs.studentsTable as Table).currentPage = 1;

    if (this.selectedRow) {
      for (let item of this.items) {
        if (includes(item, this.selectedRow.id)) {
          this.visibleDocumentUpload = false;
          this.openView();
          break;
        }
        this.closeView();
      }
    }
  }

  public onAbortStudentsFilter(data: { filter: any }): void {
    this.setCurrentFilter(data.filter);

    const currentSearchTerm: string =
      this.$store.getters["student/searchLocalFilter/getFilter"] ||
      this.$store.getters["student/localFilter/getFilter"]?.searchTerm ||
      this.query.searchTerm ||
      "";
    if (this.visibleEmailForm) {
      this.fetchEmailStudents();
    }

    this.studentFilter({
      resource: "students",
      filter: { ...data.filter, searchTerm: currentSearchTerm },
    });

    this.activeStudentsFilter = {
      limit: this.activeStudentsFilter.limit,
      offset: 0,
      searchTerm: currentSearchTerm,
    };
    this.visibleEmailForm = false;
    this.selectedEmailType = null;
    this.filteringIcon = false;
    (this.$refs.studentsTable as Table).currentPage = 1;
  }

  @ProvideReactive("selectedStudent")
  private get selectedRowReactive(): any {
    return this.selectedRow;
  }

  @ProvideReactive("drivingSchoolEnabled")
  private get currentDrivingSchoolEnabled(): any {
    return this.isDrivingSchoolEnabled;
  }

  protected get studentId(): number | null {
    return this.selectedRow ? this.selectedRow.id : 0;
  }

  protected onAbortStudentUploadDocument(): void {
    this.visibleDocumentUpload = false;
  }

  public onEmailModalShow() {
    if (this.selectedRow) {
      const options = {
        resource: "cost-bearers/student",
        id: this.selectedRow.id,
      };
      this.findCostBearerByStudentId(options);
      this.$bvModal.show("student-email-modal");
    }
  }

  public async onEmailCompose() {
    if (this.selectedEmailType) {
      this.closeView();
      this.visibleDocumentUpload = false;
      this.visibleEmailForm = true;
      this.$bvModal.hide("student-email-modal");
    }
    return;
  }

  public onEmailComposeAbort(): void {
    this.$bvModal.hide("student-email-modal");
  }

  public onEmailFormClose() {
    this.visibleEmailForm = false;
    this.selectedEmailType = null;
    this.requestQuery(this.query);
  }

  protected async onCsvExport(): Promise<void> {
    const data = this.getFilter;
    this.$bvModal.show(this.generateCsvModalId);
    //this field is needed for correct filtering
    const basicFilterData = {
      showArchived: false,
    };
    await downloadFile({
      method: "post",
      url: "students/export-with-educations",
      data: { ...basicFilterData, ...data, searchTerm: this.searchTerm, search: this.searchTerm },
    });
    this.$bvModal.hide(this.generateCsvModalId);
  }

  protected onSearchActivateField(): void {
    this.hideSearch = !this.hideSearch;
    if (this.hideSearch) {
      (this.$refs.studentsTable as Table).onSearch("");
    }
  }

  protected onSearchEvent(event: any) {
    if (event) {
      this.visibleView = false;
    }
  }

  public async fetchEmailStudents() {
    await this.filterStudents({
      resource: "students/emails",
      filter: {
        calculateSaldo: true,
        showArchived: false,
        ...this.activeStudentsFilter,
      },
    });
  }

  private async createNewEmailFormOnMounted() {
    // this is for sender mail
    this.fetchEmailsFilterAction();
    this.fetchEmailStudents();
  }

  public get studentEducationIds() {
    return this.selectedRow?.studentEducationIds || [];
  }

  public async openMessageForm() {
    if (!this.selectedRow) return;
    const { id } = this.selectedRow;
    await this.$router.push({ name: "Messaging", query: { newForm: "true" } });
    this.$root.$emit("selectedStudent", { id });
  }
}
